import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
   <>
       <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" className="navbar-brand">
                  <h1 className="m-0 text-white">
                    <i className="fa fa-user-tie me-2" />
                    Singh<span className="text-secondary">Code</span>
                  </h1>
                </a>
                <p className="mt-3 mb-4">
                We believe in transparent communication and personalized service, ensuring that you get exactly what you need to grow your business. No matter how big or small your project, we’re committed to delivering top-quality solutions that fit your budget and timeline.
                </p>
                <form action="">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">Mohali, Industrial Area, Punjab</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">singhcodetech@gmail.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">+91 7888 957 101</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-twitter fw-normal" />
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-facebook-f fw-normal" />
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-linkedin-in fw-normal" />
                    </a>
                    <a className="btn btn-primary btn-square" href="#">
                      <i className="fab fa-instagram fw-normal" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about-us">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/services">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Our Services
                    </Link>
                  
                    <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Latest Blog
                    </Link>
                    <Link className="text-light" to="contact">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                  <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about-us">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/services">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Our Services
                    </Link>
                  
                    <Link className="text-light mb-2" to="/blog">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Latest Blog
                    </Link>
                    <Link className="text-light" to="contact">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-white" style={{ background: "#061429" }}>
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  {"Copyright 2012 - 2024 "} 
                  <Link
                  className="border-bottom"
                  to="/"
                >
                     SinghCode.
                </Link>
                  {"  All Rights Reserved. Hosted By "}
                
                 
                
                </p>
                
                <a
                  className="border-bottom"
                  href="http://arshresume.epizy.com/"
                  target="_blank"
                >
                        ASK
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  );
};

export default Footer;

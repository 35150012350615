// src/Components/Header.js

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */

const Header = () => {
  return (
    <section className="hero-landing">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
            />
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={1}
              className=""
            />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active" data-interval={5000}>
              <div className="container">
                <div className="row pb-4">
                  <div className="col-md-6">
                    <div className="banner-text">
                      <h6>Perfect Blend of Technology &amp; Creativity!</h6>
                      <h1>Web &amp; App Development Company</h1>
                      <p>
                        Being a top app development company, SinghCode offers customized
                        app development solutions based on the latest technology and
                        design methods.
                      </p>
                      <a
                        href="/contact-us"
                        className="mt-md-5 mt-2 btn-main bg-btn lnk"
                      >
                        Contact Us
                        <i className="fa fa-chevron-right fa-icon" />
                        <span className="circle" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5 mt-md-0">
                    <div className="img">
                      <a
                        href="/service/4/mobile-app-development-services"
                        className="link-app"
                      >
                        App Development
                      </a>{" "}
                      <img src="assets/img/img-banner.webp" alt="banner" />{" "}
                      <a
                        href="/service/6/web-development-services"
                        className="link-web"
                      >
                        Website Development
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-interval={5000}>
              <div className="container">
                <div className="row pb-4">
                  <div className="col-md-6">
                    <div className="banner-text">
                      <h6>Delivering Success In Every Solution!</h6>
                      <h1>Custom Software Development Company</h1>
                      <p>
                        Our custom software development services are designed to meet
                        all of your business needs and assist in promoting business
                        expansion on a global scale.
                      </p>
                      <a
                        href="/contact-us"
                        className="mt-md-5 mt-2 btn-main bg-btn lnk"
                      >
                        Contact Us
                        <i className="fa fa-chevron-right fa-icon" />
                        <span className="circle" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5 mt-md-0">
                    <div className="img">
                      <a
                        href="/service/51/erp-software-development"
                        className="link-app"
                      >
                        ERP Solutions
                      </a>{" "}
                      <img src="assets/img/softwaredv.webp" alt="softwaredv" />{" "}
                      <a
                        href="/service/55/artificial-intelligence"
                        className="link-web"
                      >
                        Artificial Intelligence
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-control-prev">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <i className="fa fa-chevron-left" />
            </button>
          </div>
          <div className="carousel-control-next">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </div>
      </section>
  );
};

export default Header;

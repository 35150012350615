import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */

const AService = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
        <h1 className="mb-0">
          Custom IT Solutions for Your Successful Business
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-shield-alt text-white" />
            </div>
            <h4 className="mb-3">Cyber Security</h4>
            <p className="m-0">
            Protect your business from evolving cyber threats with our advanced cyber security services. At SinghCode, we provide comprehensive security solutions...
            </p>
            <a className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-chart-pie text-white" />
            </div>
            <h4 className="mb-3">Data Analytics</h4>
            <p className="m-0">
            Unlock the power of your data with our cutting-edge analytics solutions. At SinghCode, we transform raw data into actionable insights...


            </p>
            <a className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div>
            <h4 className="mb-3">Web Development</h4>
            <p className="m-0">
            At SinghCode, we create stunning, responsive websites tailored to your business needs. From dynamic e-commerce platforms to sleek corporate sites...
            </p>
            <a className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fab fa-android text-white" />
            </div>
            <h4 className="mb-3">Apps Development</h4>
            <p className="m-0">
            Bring your ideas to life with our custom app development solutions. At SinghCode, we design and build high-performance mobile and web applications tailored to your business needs. Whether it's iOS, Android..
            </p>
            <a className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-search text-white" />
            </div>
            <h4 className="mb-3">SEO Optimization</h4>
            <p className="m-0">
            Boost your online visibility and drive more traffic with our expert SEO optimization services. At SinghCode, we use proven strategies to improve your search engine rankings...
            </p>
            <a className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
            <h3 className="text-white mb-3">Call Us For Quote</h3>
            <p className="text-white mb-3">
            Ready to get started? Contact us today for a free, no-obligation quote and discover how we can help your business thrive.
            </p>
            <h2 className="text-white mb-0">+91 7888 957 101</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AService;

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import AService from '../components/AService';

const Service =() => {
return (
    <>
  
    {/* Full Screen Search Start */}
  
    <AService />
    {/* About End */}
   
  </>
)
};

export default Service;

// src/Components/TopBar.js

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import { Link, useLocation } from 'react-router-dom';
const TopBar = () => {
  const location = useLocation();

  return (
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 sticky-top shadow-sm">
        <Link to="/" className="navbar-brand p-0">
          <h1 className="m-0">
            <i className="fa fa-user-tie me-2" />
            Singh<span className="text-secondary">Code</span>
          </h1>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>
              Home
            </Link>
            <Link to="/about-us" className={`nav-item nav-link ${location.pathname === '/about-us' ? 'active' : ''}`}>
              About
            </Link>
            <Link to="/services" className={`nav-item nav-link ${location.pathname === '/services' ? 'active' : ''}`}>
              Services
            </Link>
            <Link to="/blog" className={`nav-item nav-link ${location.pathname === '/blog' ? 'active' : ''}`}>
              Blogs
            </Link>
            
            {/* <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Blog
              </Link>
           
            </div> */}
            {/* <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/pricing-plan" className={`dropdown-item ${location.pathname === '/pricing-plan' ? 'active' : ''}`}>
                  Pricing Plan
                </Link>
                <Link to="/features" className={`dropdown-item ${location.pathname === '/features' ? 'active' : ''}`}>
                  Our Features
                </Link>
                <Link to="/team" className={`dropdown-item ${location.pathname === '/team' ? 'active' : ''}`}>
                  Team Members
                </Link>
                <Link to="/testimonial" className={`dropdown-item ${location.pathname === '/testimonial' ? 'active' : ''}`}>
                  Testimonial
                </Link>
                <Link to="/quote" className={`dropdown-item ${location.pathname === '/quote' ? 'active' : ''}`}>
                  Free Quote
                </Link>
              </div>
            </div> */}
            <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>
              Contact
            </Link>
          </div>
          <button
            type="button"
            className="btn text-primary ms-3"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            {/* <i className="fa fa-search" /> */}
          </button>
        </div>
      </nav>

    </div>
  );
};

export default TopBar;

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import { Carousel, Navbar, Nav, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhoneAlt, faEnvelopeOpen, faUserTie, faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import Home from './Views/Home';
import { Routes,Route } from 'react-router-dom';
import AboutUs from './Views/AboutUs';
import Service from './Views/Service';
import ContactUs from './Views/ContactUs';
import Blogs from './Views/Blogs';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import Scroll from './components/Scroll';

const App = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
   <>
    <TopBar />
    <Scroll  />
   <Routes>

    <Route index path='/' element={<Home/>}  />
    <Route path='/about-us' element={<AboutUs/>}  />
    <Route path='/services' element={<Service/>}  />
    <Route path='/contact' element={<ContactUs/>}  />
    <Route path='/blog' element={<Blogs/>}  />
      
   </Routes>
   <Footer/>
   

   
   </>

  );
};

export default App;

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */


const About = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
              <h1 className="mb-0">
                The Best IT Solution With 5 Years of Experience
              </h1>
            </div>
            <p className="mb-4">
              At SinghCode, we are dedicated to delivering innovative IT solutions that empower businesses to thrive in today’s digital landscape. With 5 years of industry experience, we’ve built a reputation for excellence, reliability, and customer-centric service. Our team of expert developers, IT consultants, and tech enthusiasts work tirelessly to provide tailored solutions that meet the unique needs of each client.
            </p>
            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Award Winning
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Professional Staff
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  24/7 Support
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3" />
                  Fair Prices
                </h5>
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-4 wow fadeIn"
              data-wow-delay="0.6s"
            >
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-phone-alt text-white" />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+91 7888 957 101</h4>
              </div>
            </div>

          </div>
          <div className="col-lg-5 " style={{ minHeight: 500 }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute mt-5 w-100 h-95 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src="assets/img/network-communication-illustrations-3585393.webp"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

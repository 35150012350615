import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */

const SearchStart = () => {
  return (
    <div className="container-fluid facts py-5 pt-lg-0">
    <div className="container py-5 pt-lg-0">
      <div className="row gx-0">
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-users text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Happy Clients</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">
                120+
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
          <div
            className="bg-light shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-check text-white" />
            </div>
            <div className="ps-4">
              <h5 className="text-primary mb-0">Projects Done</h5>
              <h1 className="mb-0" data-toggle="counter-up">
             34+   
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-award text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Win Awards</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">
                43+
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="container py-5 pt-lg-0">
      <div className="row gx-0">
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-users text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Team Members</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">
                50+
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
          <div
            className="bg-light shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-check text-white" />
            </div>
            <div className="ps-4">
              <h5 className="text-primary mb-0">Hours Worked</h5>
              <h1 className="mb-0" data-toggle="counter-up">
              13M+
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-award text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Technologies Covered</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">
                45+
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SearchStart;

import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Latest Blog</h5>
        <h1 className="mb-0">Read The Latest Articles from Our Blog Post</h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="blog-item bg-light rounded overflow-hidden">
            <div className="blog-img position-relative overflow-hidden">
              <img className="img-fluid" src="assets/img/blog-1.jpg" alt="" />
              <Link
                className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                to=""
              >
                Celebration
              </Link>
            </div>
            <div className="p-4">
              <div className="d-flex mb-3">
              
                <small>
                  <i className="far fa-calendar-alt text-primary me-2" />
                  30 Jan, 2024
                </small>
              </div>
              <h4 className="mb-3">Month-End Celebration</h4>
              <p>
              ​Together We Thrive: A Month-End Celebration of Teamwork at SinghCode Technologies
              </p>
              <Link className="text-uppercase" to="">
                Read More <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="blog-item bg-light rounded overflow-hidden">
            <div className="blog-img position-relative overflow-hidden">
              <img className="img-fluid" src="assets/img/blog-2.jpg" alt="" />
              <Link
                className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                to=""
              >
                Friday Team Outing
              </Link>
            </div>
            <div className="p-4">
              <div className="d-flex mb-3">
              
                <small>
                  <i className="far fa-calendar-alt text-primary me-2" />
                   30 Jan, 2024
                </small>
              </div>
              <h4 className="mb-3">How to build a website</h4>
              <p>
              Friday Team Outing: Celebrating a Productive Week
              </p>
              <Link className="text-uppercase" to="">
                Read More <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div className="blog-item bg-light rounded overflow-hidden">
            <div className="blog-img position-relative overflow-hidden">
              <img className="img-fluid" src="assets/img/intelligence-technology.png" alt="" />
              <Link
                className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                to=""
              >
              AI
              </Link>
            </div>
            <div className="p-4">
              <div className="d-flex mb-3">
               
                <small>
                  <i className="far fa-calendar-alt text-primary me-2" />
                  01 Jan, 2045
                </small>
              </div>
              <h4 className="mb-3">How to Integrate AI</h4>
              <p>
              How Do We Integrate AI Into an App: A Complete Guide
              </p>
              <Link className="text-uppercase" to="">
                Read More <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Blog;

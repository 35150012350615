import React from 'react';

/**
 *@copyright : ASk. < http://arshresume.epizy.com/ >
 *@author	 : Arshdeep Singh < arshdeepsinghjoshan84@gmail.com >
 *
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ASK. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import SearchStart from '../components/SearchStart';
import About from '../components/About';
import Features from '../components/Features';
import AService from '../components/AService';
import Quote from '../components/Quote';
import Blog from '../components/Blog';
import Header from '../components/Header';


const Home =() => {
return (
    <>
    


   


    <Header/>
    {/* Navbar & Carousel End */}
    {/* Full Screen Search Start */}
    <SearchStart />
    {/* Full Screen Search End */}
    {/* Facts Start */}

    {/* Facts Start */}
    {/* About Start */}
    <About />
    {/* About End */}
    {/* Features Start */}
   <Features/>
    {/* Features Start */}
    {/* AService Start */}
   <AService/>
    {/* AService End */}

    {/* Quote Start */}
   <Quote/>
    {/* Quote End */}

  
    {/* Team End */}
    {/* Blog Start */}
<Blog/>
    {/* Blog Start */}

  </>
)
};

export default Home;
